import * as PIXI from 'pixi.js';
import { Container, Sprite } from 'pixi.js';

import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenBuyFeature,
  setBrokenGame,
  setBrokenPickem,
  setIsBuyFeaturePopupOpened,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
} from '../../gql';
import { isPickemMode, isRegularMode } from '../../utils';
import Button from '../button';
import { eventManager } from '../config';

class InfoBtn extends Container {
  private gameMode: GameMode;

  private isAutoSpins: boolean;

  private isSpinInProgress: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  public btn: Button;

  private rect: Sprite;

  constructor() {
    super();
    if (setBrokenBuyFeature()) {
      this.gameMode = GameMode.BUY_FEATURE;
    } else if (setBrokenGame()) {
      this.gameMode = GameMode.FREE_SPINS;
    } else if (setBrokenPickem()) {
      this.gameMode = GameMode.PICKEM;
    } else {
      this.gameMode = GameMode.REGULAR;
    }
    this.interactive = true;
    this.rect = new PIXI.Sprite();
    this.rect.width = 75;
    this.rect.height = 75;
    this.rect.anchor.set(0.5);
    this.addChild(this.rect);
    this.btn = new Button('infomation', 75);
    this.btn.btnSheet = PIXI.Loader.shared.resources.infoBtnSprite.spritesheet!;
    this.btn.updateIntent('infomation');
    this.btn.scale.set(0.556);
    this.isAutoSpins = false;
    this.isSpinInProgress = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    this.addChild(this.btn);
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_MODE_CHANGE_FADE, () => this.btn.setDisable(true));
  };

  private handleClick = (): void => {
    if (!this.btn.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE) {
      setIsModalOpeningInProgress(ModalOpeningTypes.MENU);

      setIsOpenMenuModal({
        isOpen: !setIsOpenMenuModal().isOpen,
        type: 'info',
      });
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  };

  private handleDisable = (): void => {
    this.btn.setDisable(
      (!isRegularMode(this.gameMode) && !isPickemMode(this.gameMode)) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        (this.isFreeSpinsWin && !isPickemMode(this.gameMode)) ||
        setIsBuyFeaturePopupOpened(),
    );
  };
}

export default InfoBtn;
