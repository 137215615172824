import * as _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import variables from '../../assets/styles/export.module.scss';
import { ISongs } from '../../config';
import { EventTypes, ModalOpeningTypes } from '../../global.d';
import { setBrokenGame, setIsAutoSpins, setIsLeftHandMode } from '../../gql';
import { setBrokenPickem, setIsModalOpeningInProgress } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode, isPickemMode } from '../../utils';
import { TextField } from '../components/TextField';
import {
  FEATURE_BTN_CONTAINER_POSITION_X,
  FEATURE_BTN_CONTAINER_POSITION_Y,
  FEATURE_BTN_HEIGHT,
  FEATURE_BTN_WIDTH,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: TextField;

  isDisabled: boolean;

  resizedWidth: number;

  resizedHeight: number;

  isLeftHandMode: boolean;

  constructor() {
    super();
    this.x = FEATURE_BTN_CONTAINER_POSITION_X;
    this.y = FEATURE_BTN_CONTAINER_POSITION_Y;
    this.isDisabled = false;
    this.visible = !(setBrokenGame() || setBrokenPickem());
    this.btn = this.initBuyFeatureBtn();
    this.resizedWidth = 0;
    this.resizedHeight = 0;
    this.isLeftHandMode = setIsLeftHandMode();
    this.addChild(this.btn);

    this.text = this.initBuyFeatureText();
    this.addChild(this.text.getText());

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, (isVisible: boolean) => (this.visible = !isVisible));
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLhm: boolean) => {
      this.isLeftHandMode = isLhm;
      this.handlePosition();
    });

    eventManager.on(EventTypes.CHANGE_MODE, (data) => {
      if (isPickemMode(data.mode) || isFreeSpinMode(data.mode)) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    });
    eventManager.on(EventTypes.RESIZE, this.resize.bind(this));
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    btn.width = FEATURE_BTN_WIDTH;
    btn.height = FEATURE_BTN_HEIGHT;
    btn.x = 0;
    btn.y = 0;
    btn.interactive = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', (e) => {
      if (!this.isDisabled) {
        AudioHowl.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnHover);

        this.text.text.style = buyFeatureHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);

        this.text.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnPressed);

        this.text.text.style = buyFeatureClickTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);

        this.text.text.style = buyFeatureTextStyle;
      }
    });
    return btn;
  }

  private initBuyFeatureText(): TextField {
    const text = new TextField(i18n.t<string>('buyFeatureBtn'), 120, 150, buyFeatureTextStyle);

    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(this.width / 2, this.height / 2);
    text.text.resolution = 1;
    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
      AudioHowl.play({ type: ISongs.BuyButton });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnNotActive);

      this.text.text.style = buyFeatureDisableTextStyle;
    } else {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);

      this.text.text.style = buyFeatureTextStyle;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private resize(width: number, height: number): void {
    this.resizedWidth = width;
    this.resizedHeight = height;
    this.handlePosition();
  }

  private handlePosition(): void {
    if (this.resizedWidth < this.resizedHeight && isMobile) {
      this.x = this.isLeftHandMode ? GAME_CONTAINER_WIDTH - FEATURE_BTN_WIDTH - 50 : 50;
      this.y = GAME_CONTAINER_HEIGHT + FEATURE_BTN_HEIGHT + 50;
      return;
    }
    if (
      this.resizedWidth >= parseInt(variables.breakpointTabletLandscapeMd, 10) &&
      this.resizedHeight <= parseInt(variables.breakpointTabletLandscapeXs, 10) &&
      isMobile
    ) {
      this.x = this.isLeftHandMode ? GAME_CONTAINER_WIDTH : FEATURE_BTN_CONTAINER_POSITION_X / 1.2;
    } else {
      this.x = this.isLeftHandMode && isMobile ? GAME_CONTAINER_WIDTH + 50 : FEATURE_BTN_CONTAINER_POSITION_X;
    }
    this.y = FEATURE_BTN_CONTAINER_POSITION_Y;
  }
}

export default BuyFeatureBtn;
