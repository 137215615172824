import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame } from '../../gql';
import { setBrokenPickem, setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class Background extends ViewContainer {
  private baseBg = PIXI.Texture.from(ResourceTypes.slotBg);

  private pickermBg = PIXI.Texture.from(ResourceTypes.pickemBg);

  private bonusBg = PIXI.Texture.from(ResourceTypes.slotFreeSpinBg);

  private bgSprite = new PIXI.Sprite();

  private ambientBaseAnimation: SpineAnimation | null = null;

  constructor() {
    super();

    if (setBrokenPickem()) {
      this.bgSprite.texture = this.pickermBg;
    } else if (setBrokenGame()) {
      this.bgSprite.texture = this.bonusBg;
    } else {
      this.bgSprite.texture = this.baseBg;
    }
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);
    this.initAnimation();

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeModeBackground.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeModeBackground.bind(this));

    if (!setBrokenPickem() && !setBrokenGame()) {
      this.startAnimation('main_ambient');
    }

    if (setBrokenGame()) {
      this.startAnimation('main_ambient');
    }
  }

  private initAnimation(): void {
    this.ambientBaseAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.ambient.spineData!);
    this.ambientBaseAnimation.addOnStart(() => {
      this.addChild(this.ambientBaseAnimation!.spine);
    });
  }

  private startAnimation(anim: string): void {
    this.ambientBaseAnimation!.setAnimation(anim, true);
    this.ambientBaseAnimation!.start();
  }

  private changeModeBackground(settings: { mode: GameMode }) {
    if (isFreeSpinMode(settings.mode)) {
      this.bgSprite.texture = this.bonusBg;
      this.ambientBaseAnimation!.spine.visible = true;
      this.startAnimation('fs_ambient');
    }
    if (settings.mode === GameMode.PICKEM) {
      this.bgSprite.texture = this.pickermBg;
      this.ambientBaseAnimation!.spine.visible = false;
    }

    if (settings.mode === GameMode.REGULAR) {
      this.bgSprite.texture = this.baseBg;
      this.ambientBaseAnimation!.spine.visible = true;
      this.startAnimation('main_ambient');
    }
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default Background;
