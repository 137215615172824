import * as PIXI from 'pixi.js';

import { setBrokenGame } from '../../gql';
import { setBrokenPickem } from '../../gql/cache';
import { destroySpine } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { GAME_CONTAINER_WIDTH } from '../config';

class GameTitle extends ViewContainer {
  private spine: SpineAnimation | undefined;

  constructor() {
    super();

    this.initGameLogo();
  }

  private initGameLogo(): void {
    if (this.spine) {
      this.removeChild(this.spine.getSpine());
      destroySpine(this.spine);
    }
    this.spine = new SpineAnimation({}, PIXI.Loader.shared.resources.logo.spineData!);
    this.spine.addOnStart(() => {
      const spineData = this.spine!.getSpine();
      spineData.x = GAME_CONTAINER_WIDTH / 2;
      spineData.y = 100;
      this.spine?.spine.scale.set(0.9);
      this.addChild(spineData);
      this.spine!.setAnimation('logo', true);
    });
    this.spine.start();
    this.visible = !setBrokenGame() && !setBrokenPickem();
  }
}

export default GameTitle;
