import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Toast } from '@phoenix7dev/shared-components';

import { ISongs } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import {
  configGql,
  setGameMode,
  setIsDuringBigWinLoop,
  setIsErrorMessage,
  setIsProcessToGame,
  setIsRevokeThrowingError,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSoundOn,
  setIsSpinInProgress,
  setIsSuspended,
  setIsTimeoutErrorMessage,
  setPrevReelsPosition,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
} from '../../gql';
import { IConfig } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import Animator from '../../slotMachine/animations/animator';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, SlotMachineState, eventManager } from '../../slotMachine/config';
import { getSoundToPlay, getSpinResult, isFreeSpinMode, wrap } from '../../utils';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsMenu';
import BetSettingsMenu from '../BetSettings/BetSettingsMenu';
import BuyFeature from '../BuyFeature';
import Clock from '../Clock';
import EventListener from '../EventListener';
import IntroScreen from '../IntroScreen/introScreen';
import Menu from '../MenuButton';
import Spin from '../SpinButton';

import styles from './gameScreen.module.scss';

export interface IPixiViewParentNode extends Node, ParentNode {
  clientWidth: number;
  clientHeight: number;
}

export const application = new PIXI.Application({
  resolution: window.devicePixelRatio || 1,
  autoDensity: true,
  backgroundAlpha: 0,
  width: GAME_CONTAINER_WIDTH,
  height: GAME_CONTAINER_HEIGHT,
});

export const animator = new Animator(application);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.PIXI = PIXI;
(globalThis as unknown as { __PIXI_APP__: unknown }).__PIXI_APP__ = application;

const GameScreen: React.FC = () => {
  const { t } = useTranslation();
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isMiniPayTable } = clientData!;
  const { isSoundOn, isShowSuspendedToast } = clientData!;
  const skipIntro = useReactiveVar(setSkipIntroScreen);
  const processToGame = useReactiveVar(setIsProcessToGame);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const isLoading = useReactiveVar(setIsSoundLoading);

  useEffect(() => {
    AudioHowl.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isMiniPayTable);
    if (!isMiniPayTable) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
    }
  }, [isMiniPayTable]);

  useEffect(() => {
    const handleResize = (): void => {
      const parent = pixiContainerRef.current!;
      const width = parent.clientWidth;
      const height = parent.clientHeight;
      eventManager.emit(EventTypes.RESIZE, width, height);
    };

    const resize = (): void => {
      const { userAgent } = navigator;
      // resize fix for Chrome browsers on Ios devices
      if (userAgent.includes('CriOS') && (userAgent.includes('iPhone') || userAgent.includes('iPad'))) {
        setTimeout(() => {
          handleResize();
        }, 50);
      } else {
        handleResize();
      }
    };

    pixiContainerRef.current?.appendChild(application.view);
    if (!setSkipIntroScreen()) {
      IntroScreen.initIntroScreen(application);
      eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        SlotMachine.initSlotMachine(
          application,
          setSlotConfig(),
          wrap(setIsSpinInProgress, false),
          wrap(setIsSlotBusy, false),
        );
        // Overriding these methods to avoid certified files.
        SlotMachine.getInstance().setResult = (result: ISettledBet) => {
          const spinResult = getSpinResult({
            reelPositions: result.bet.result.reelPositions.slice(0, 5),
            reelSet: result.bet.reelSet,
            icons: setSlotConfig().icons,
          });
          const newResult = {
            ...result,
            bet: {
              ...result.bet,
              result: {
                ...result.bet.result,
                spinResult,
              },
            },
          };
          setUserLastBetResult(newResult.bet);
          setPrevReelsPosition(newResult.bet.result.reelPositions.slice(0, 5));
          SlotMachine.getInstance().nextResult = newResult;
          if (!isFreeSpinMode(setGameMode())) {
            eventManager.emit(EventTypes.UPDATE_USER_BALANCE, SlotMachine.getInstance().nextResult?.balance.placed);
          }
        };

        SlotMachine.getInstance().throwTimeoutError = () => {
          if (!setIsRevokeThrowingError()) {
            setIsTimeoutErrorMessage(true);
            setIsErrorMessage(true);
          }
          eventManager.emit(EventTypes.THROW_ERROR);
        };

        SlotMachine.getInstance().onSpinStop = () => {
          if (setIsErrorMessage()) {
            SlotMachine.getInstance().state = SlotMachineState.IDLE;
            eventManager.emit(EventTypes.DISABLE_PAY_TABLE, !isFreeSpinMode(setGameMode()));
            eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.IDLE);
            setIsSpinInProgress(false);
            setIsErrorMessage(false);
            eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
          } else {
            wrap(setIsSpinInProgress, false)();
            SlotMachine.getInstance().miniPayTableContainer.setSpinResult(
              SlotMachine.getInstance().nextResult!.bet.result.spinResult,
            );
            SlotMachine.getInstance().stickyWildsContainer.setSpinResult(
              SlotMachine.getInstance().nextResult!.bet.data.features.multipliers,
            );
            SlotMachine.getInstance().state = SlotMachineState.JINGLE;
            eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
            eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.JINGLE);
          }
        };
        handleResize();
      });
    } else {
      setIsProcessToGame(true);
      SlotMachine.initSlotMachine(
        application,
        setSlotConfig(),
        wrap(setIsSpinInProgress, false),
        wrap(setIsSlotBusy, false),
      );
      // Overriding these methods to avoid certified files.
      SlotMachine.getInstance().setResult = (result: ISettledBet) => {
        const spinResult = getSpinResult({
          reelPositions: result.bet.result.reelPositions.slice(0, 5),
          reelSet: result.bet.reelSet,
          icons: setSlotConfig().icons,
        });
        const newResult = {
          ...result,
          bet: {
            ...result.bet,
            result: {
              ...result.bet.result,
              spinResult,
            },
          },
        };
        setUserLastBetResult(newResult.bet);
        setPrevReelsPosition(newResult.bet.result.reelPositions.slice(0, 5));
        SlotMachine.getInstance().nextResult = newResult;
        if (!isFreeSpinMode(setGameMode())) {
          eventManager.emit(EventTypes.UPDATE_USER_BALANCE, SlotMachine.getInstance().nextResult?.balance.placed);
        }
      };

      SlotMachine.getInstance().throwTimeoutError = () => {
        if (!setIsRevokeThrowingError()) {
          setIsTimeoutErrorMessage(true);
          setIsErrorMessage(true);
        }
        eventManager.emit(EventTypes.THROW_ERROR);
      };

      SlotMachine.getInstance().onSpinStop = () => {
        if (setIsErrorMessage()) {
          SlotMachine.getInstance().state = SlotMachineState.IDLE;
          eventManager.emit(EventTypes.DISABLE_PAY_TABLE, !isFreeSpinMode(setGameMode()));
          eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.IDLE);
          setIsSpinInProgress(false);
          setIsErrorMessage(false);
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        } else {
          wrap(setIsSpinInProgress, false)();
          SlotMachine.getInstance().miniPayTableContainer.setSpinResult(
            SlotMachine.getInstance().nextResult!.bet.result.spinResult,
          );
          SlotMachine.getInstance().stickyWildsContainer.setSpinResult(
            SlotMachine.getInstance().nextResult!.bet.data.features.multipliers,
          );
          SlotMachine.getInstance().state = SlotMachineState.JINGLE;
          eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
          eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.JINGLE);
        }
      };
      handleResize();
    }

    handleResize();

    window.addEventListener(EventTypes.RESIZE, resize);
    return () => window.removeEventListener(EventTypes.RESIZE, resize);
  }, []);

  return (
    <>
      <div className={styles.canvas} ref={pixiContainerRef} />
      <Spin />
      {processToGame && (
        <>
          <EventListener />
          <Clock />
          <BuyFeature />
          <AutoPlaySettingsMenu />
          <Menu />
          <BetSettingsMenu />
          {AudioHowl.isRestricted &&
            !AudioHowl.restrictionChangedOnIntroScreen &&
            !AudioHowl.hideRestrictionModal &&
            !isShowSuspendedToast &&
            setIsSoundOn() && (
              <Toast
                title={t('soundWarningTitle')}
                text={t('soundWarningText')}
                btnText={t('soundWarningBtn')}
                isLoading={isLoading}
                handleClick={() => {
                  const soundToPlay = getSoundToPlay();

                  if (!AudioHowl.isInitialized) {
                    eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
                  }

                  AudioHowl.changeRestriction(
                    false,
                    soundToPlay,
                    () => setIsSoundLoading(true),
                    () => setIsShowSoundToast(false),
                  );
                }}
              />
            )}
          {isShowSuspendedToast && (
            <Toast
              title={t('suspendedWarningTitle')}
              text={t('suspendedWarningText')}
              btnText={t('suspendedWarningBtn')}
              handleClick={() => {
                setIsSuspended(false);
                AudioHowl.unSuspend();
                const soundToPlay = getSoundToPlay();

                if (!AudioHowl.isInitialized) {
                  eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
                }

                AudioHowl.changeRestriction(
                  false,
                  soundToPlay,
                  () => setIsSoundLoading(true),
                  () => setIsShowSoundToast(false),
                );
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default GameScreen;
